<template>
  <div>
    <el-dialog
      title="充 值"
      :visible.sync="dialogVisible"
      width="480px"
      :close-on-click-modal="false"
      class="accounts"
    >
      <el-form ref="formDetail" :model="formDetail" label-width="120px" :rules="rules">
        <el-form-item v-if="continuePayMoney == ''" label="充值金额：" prop="price">
          <div>
            <el-input
              v-model="formDetail.price"
              placeholder="请输入充值金额"
              clearable
              @input.native="clearNoNum"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="支付方式：">
          <el-radio-group v-model="formDetail.radio">
            <div class="radio_wrap">
              <el-radio label="ALIPAY">
                <img src="../../../../assets/college/Accounts/alipay_icon.png" alt="" />
                <span>支付宝</span>
              </el-radio>
            </div>
            <div class="radio_wrap">
              <el-radio label="WXPAY">
                <img
                  src="../../../../assets/college/Accounts/wxpay_icon.png"
                  alt=""
                  class="weixin"
                />
                <span>微信</span>
              </el-radio>
            </div>
            <div class="radio_wrap">
              <el-radio label="THIRD">
                <img
                  src="../../../../assets/college/Accounts/ylpay_icon.png"
                  alt=""
                  class="yinlian"
                />
                <span>公对公打款</span>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-show="formDetail.radio == 'THIRD'" class="account-info">
        <div class="card">
          <div>
            <div class="key">开户名称：</div>
            <div class="value">武汉市中潮互联网教育有限公司</div>
          </div>
          <div>
            <div class="key">开户银行：</div>
            <div class="value">中国银行股份有限公司武汉东湖新技术开发区分行</div>
          </div>
          <div>
            <div class="key">开户行号：</div>
            <div class="value">559 9678 7400 3</div>
          </div>
        </div>
        <div class="tip">
          温馨提示：线下汇款直接向平台的账户汇款到账时间一般为：招行1-2天，跨行2-5天（具体到账时间以银行的实际到账时间为准）。我们收到汇款会第一时间处理。
        </div>
        <div class="upload">
          <div class="key">上传银行回执凭证（选填）：</div>
          <el-upload
            class="uploader"
            action=""
            :show-file-list="false"
            :http-request="uploadRequest"
            :before-upload="beforeAvatarUpload"
            accept=".jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG"
          >
            <img v-if="image" :src="image" class="avatar" />
            <template v-else>
              <i class="el-icon-plus"></i>
              <p>点击上传</p>
            </template>
          </el-upload>
          <div class="demo">
            <a :href="require('@/assets/college/Accounts/huizhi.jpg')" target="_blank">
              <img src="@/assets/college/Accounts/huizhi.jpg" alt=""
            /></a>
            <p>示例图片</p>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit('formDetail')">确认支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
import { getEnterpriseRecharge } from '@/api/college'
export default {
  data() {
    var checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入充值金额'))
      } else if (value <= 0) {
        callback(new Error('请输入充值金额'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      rules: {
        price: [
          { required: true, validator: checkPrice, trigger: 'blur' },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确的格式,可保留两位小数',
          },
        ],
      },
      formDetail: {
        price: '',
        radio: 'ALIPAY',
      },
      continuePayMoney: '',
      orderId: '',
      image: '',
      fileList: [],
    }
  },
  mounted() {
    if (this.$route.params.showRecharge == 1) {
      setTimeout(() => {
        this.show()
      }, 500)
    }
  },
  methods: {
    show(continuePayMoney, queryOrderId) {
      this.formDetail = {
        price: '',
        radio: 'ALIPAY',
      }
      this.image = ''
      this.fileList = []
      this.dialogVisible = true
      if (continuePayMoney) {
        this.continuePayMoney = continuePayMoney
      }
      if (queryOrderId) {
        this.orderId = queryOrderId
      }
    },
    async submit(formName) {
      var host = window.location.host
      var url = 'https://' + host + '/college/payStatus'
      let obj = {
        addOrganiztaionOrderDetailReqDtos: [
          {
            amount: this.continuePayMoney ? this.continuePayMoney : this.formDetail.price,
          },
        ],
        orderAmount: this.continuePayMoney !== '' ? this.continuePayMoney : this.formDetail.price,
        orderSourseType: 1,
        orderSourseTypePay: 1,
        orderType: 4, //收费类型
        payType: this.formDetail.radio,
        returnUrl: url,
        orderId: this.orderId,
      }
      if (this.formDetail.radio == 'THIRD') obj.bankReceipt = this.image || ''
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const [res, err] = await to(getEnterpriseRecharge(obj))
          if (err) return this.$message({ type: 'error', message: err.msg })
          if (this.formDetail.radio == 'ALIPAY') {
            const dom = res.data.content
            let payDiv = document.createElement('div')
            payDiv.innerHTML = dom
            document.body.appendChild(payDiv)
            document.querySelector("form[name='punchout_form']").submit()
          } else if (this.formDetail.radio == 'WXPAY') {
            sessionStorage.setItem('paySuccessPath', this.$route.path)
            this.$router.push({
              path: '/college/wxpay',
              query: {
                orderType: 4,
                paytype: this.formDetail.radio,
                orderNo: res.data.orderNo,
              },
            })

            sessionStorage.setItem('urlCode', res.data.content)
            sessionStorage.setItem('codeObj', JSON.stringify(obj))
          } else {
            this.$message({ type: 'success', message: '请等待审核' })
          }
          this.dialogVisible = false
        }
      })
    },
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.image = ''
        return this.$message.error(err.msg)
      }
      this.image = res.data
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    clearNoNum(e) {
      var temp = e.target.value
      if (temp < 0) {
        this.formDetail.price = ''
        return
      }
      var posDot = temp.indexOf('.') //返回指定字符在此字符串中第一次出现处的索引
      if (posDot < 0) {
        //不包含小数点
        if (temp.length <= 7) {
          return //小于五位数直接返回
        } else {
          if (temp.length > 7) {
            this.formDetail.price = temp.slice(0, 7)
          }
          return
        }
      }
      var lastDot = temp.lastIndexOf('.')
      if (posDot != lastDot) {
        this.formDetail.price = ''
        return
      }
      //走到这有小数点
      if (posDot > 7) {
        // edt.delete(5, 6);//大于五位数就删掉第六位（只会保留五位）
        if (temp.length > 7) {
          this.formDetail.price = temp.slice(0, 7)
        }
        return
      }
      if (temp.length - posDot - 1 > 2) {
        //如果包含小数点
        // edt.delete(posDot + 3, posDot + 4);//删除小数点后的第三位
        this.formDetail.price = temp.substring(0, posDot + 3)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.accounts {
  ::v-deep .el-dialog__header {
    height: 55px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 55px;
    padding: 0 14px 0 17px;
    border-bottom: 1px solid #e2e2e2;
  }
  ::v-deep .el-dialog__body {
    padding: 25px 15px 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #e2e2e2;
    .el-button {
      width: 100px;
      height: 32px;
      background: #ff7b33;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .el-input {
    width: 242px;
  }
  .el-radio-group {
    padding-top: 10px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .radio_wrap {
    display: flex;
    align-items: center;
    height: 23px;
    margin-bottom: 14px;
    img {
      height: 23px;
      width: 23px;
      margin-right: 6px;
      display: flex;
      align-items: center;
      &.yinlian {
        width: 22px;
        height: 14px;
      }
    }
    ::v-deep .el-radio {
      height: 23px;
      display: flex;
      align-items: center;
      .el-radio__label {
        padding-left: 8px;
        display: flex;
        align-items: center;
      }
    }
    i {
      font-size: 24px;
      color: #ff7b33;
    }
  }
  .account-info {
    .card {
      width: 451px;
      height: 105px;
      background: #f5f5f5;
      border-radius: 8px;
      padding: 15px 0 3px 24px;
      box-sizing: border-box;
      margin-bottom: 14px;
      > div {
        font-size: 14px;
        height: 14px;
        font-weight: 400;
        color: #909399;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        .key {
          color: #909399;
        }
        .value {
          color: #333333;
        }
      }
    }
    .tip {
      width: 451px;
      margin: 0 auto;
      line-height: 22px;
      font-size: 12px;
      color: #909399;
      margin-bottom: 25px;
    }
  }
  .upload {
    display: flex;
    margin-bottom: 60px;
    .key {
      margin-right: 7px;
    }
    > .demo {
      margin-left: 10px;
      img {
        width: 121px;
        height: 93px;
        margin-bottom: 11px;
      }
      p {
        width: 121px;
        text-align: center;
        font-size: 14px;
        color: #909399;
      }
    }
  }
  ::v-deep.uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      position: relative;
      width: 98px;
      height: 95px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #c2c2c2;

      img {
        @extend %width-height;
      }
      i {
        font-size: 20px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 700;
      }
      p {
        line-height: 14px;
        font-size: 14px;
        color: #909399;
      }
      &:hover {
        border-color: #ff7b33;
      }
    }
  }
}
</style>
