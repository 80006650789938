<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-05-17 17:25:19
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-17 17:48:27
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      class="seat-log"
    >
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
      <table-list
        title="席位购买记录"
        :loading="loading"
        :data="logList"
        :columns="columns(this)"
        :btns="btns(this)"
        :pager="pager"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-dialog>
  </div>
</template>

<script>
// 顶部按钮
const btns = _this => [
  {
    label: '增加席位',
    method: _this.addSeat,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderNo',
    label: '订单号',
  },
  {
    prop: 'seatNum',
    label: '席位数量',
  },
  {
    prop: 'seatTime',
    label: '年限',
    formatter: row => {
      return row.seatTime + '年'
    },
  },
  {
    prop: 'settleTime',
    label: '购买时间',
  },
  {
    prop: 'validStatus',
    label: '状态',
    formatter: row => {
      return ['即将到期', '生效中', '已过期'][row.validStatus]
    },
  },
]
import to from 'await-to'
import { getVersionHistory } from '@/api/college'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      columns,
      btns,
      loading: false,
      dialogVisible: false,
      logList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  created() {},
  methods: {
    show() {
      this.dialogVisible = true
      this.getSeatLog()
    },
    async getSeatLog() {
      this.loading = true
      const [res, err] = await to(getVersionHistory(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.logList = res.data.list
      this.pager.total = res.data.total
    },
    addSeat() {
      this.$router.push('addSeat')
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getSeatLog()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getSeatLog()
    },
  },
}
</script>

<style scoped lang="scss">
.seat-log {
  ::v-deep.el-dialog {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 6px;
      background: #ff7b33;
    }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding-top: 50px;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-tabs__item {
      font-size: 18px;
      font-weight: 400;
      color: #4d4e5d;
      margin-bottom: 5px;
      padding: 0 18px;
      &.is-active {
        color: #ff7b33;
        font-weight: 500;
      }
      &.is-top:nth-child(2) {
        padding-left: 36px;
      }
    }
    .el-tabs__active-bar {
      height: 4px;
    }
    .el-tabs__content {
      padding: 0 30px;
    }
  }
}
</style>
