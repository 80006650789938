<template>
  <div class="accountsManage">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="cardTitle">账户管理</span>
      </div>
      <div>
        <div class="cardContent">
          <div class="cardContent_l">
            <div class="cardContent_l_l">
              <img
                v-if="seatDetail.chargePackageCode == 'V_FREE'"
                src="../../../assets/college/Accounts/bgc.png"
                alt=""
              />
              <img v-else src="../../../assets/college/Accounts/enterprise.png" alt="" />
              <div class="cardContent_l_info">
                <div
                  class="cardContent_l_nowEdition"
                  :class="{ enterprise_nowEdition: seatDetail.chargePackageCode !== 'V_FREE' }"
                >
                  当前版本
                </div>
                <div
                  class="cardContent_l_free"
                  :class="{ enterprise_edition: seatDetail.chargePackageCode !== 'V_FREE' }"
                >
                  {{ seatDetail.chargePackageName }}
                </div>
                <div
                  v-if="seatDetail.chargePackageCode == 'V_FREE'"
                  class="cardContent_l_upgrade"
                  @click="handleUpgrade"
                >
                  升级为企业版
                </div>
                <div v-else class="cardContent_l_time">
                  {{ seatDetail.validTime }}
                  <span :class="{ spanActive: seatDetail.chargePackageCode !== 'V_FREE' }"
                    >到期</span
                  >
                </div>
              </div>
            </div>
            <div class="cardContent_l_r">
              <div class="seat">
                <div class="info">
                  <div class="cardContent_l_r_people">
                    <span
                      >使用人数席位：{{ seatDetail.stuNum }}
                      <span v-if="seatDetail.stuNum">/</span> {{ seatDetail.stuValidNum }}</span
                    >
                  </div>
                  <div class="cardContent_l_r_progress">
                    <el-progress
                      v-if="!isNaN(parseInt((seatDetail.stuNum / seatDetail.stuValidNum) * 100))"
                      :percentage="parseInt((seatDetail.stuNum / seatDetail.stuValidNum) * 100)"
                      :color="'#ff7b33'"
                      :stroke-width="12"
                    ></el-progress>
                  </div>
                </div>
                <div class="btns">
                  <div
                    v-if="seatDetail.chargePackageCode == 'V_FREE'"
                    class="cardContent_l_r_detail"
                    @click="version"
                  >
                    版本介绍
                  </div>
                  <template v-else>
                    <div class="addSeat" @click="addSeat">增加席位</div>
                    <div class="addSeat" @click="seatLog">购买记录</div>
                  </template>
                </div>
              </div>
              <div v-if="seatDetail.chargePackageCode != 'V_FREE'" class="give">
                <div class="info">
                  <div class="cardContent_l_r_people">
                    <span
                      >领取免费课程：{{ giveDetail.receiveNum }} <span>/</span>
                      {{ giveDetail.avaiableNum }}</span
                    >
                  </div>
                  <div class="cardContent_l_r_progress">
                    <el-progress
                      v-if="
                        !isNaN(parseInt((giveDetail.receiveNum / giveDetail.avaiableNum) * 100))
                      "
                      :percentage="parseInt((giveDetail.receiveNum / giveDetail.avaiableNum) * 100)"
                      :color="'#ff7b33'"
                      :stroke-width="12"
                    ></el-progress>
                  </div>
                </div>
                <div class="btns">
                  <template v-if="seatDetail.chargePackageCode != 'V_FREE'">
                    <div v-if="giveDetail.isReceived" class="addSeat" @click="toFreeCourseList">
                      查看课程
                    </div>
                    <div v-if="!giveDetail.isReceived" class="addSeat" @click="getFreeCourse">
                      领取课程
                    </div>
                    <!-- 查看课程 -->
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="cardContent_r">
            <div class="top">
              <div class="cardContent_r_l">
                <div class="cardContent_r_l_balance">账户余额</div>
                <div class="cardContent_r_l_money">{{ accountsDetail.balanceAmount }}</div>
                <div class="cardContent_r_l_detailed" @click="goDetailed">收支明细</div>
              </div>
              <div class="cardContent_r_r">
                <el-button type="primary" size="medium" @click="recharge">充值</el-button>
                <!-- <el-button size="medium" @click="withdrawal">提现</el-button> -->
              </div>
            </div>
            <div class="bottom">
              <div>
                <div>
                  <img src="../../../assets/college/Accounts/wrap.png" alt="" />
                  <span>可提现金额</span>
                </div>
                <div class="money">{{ accountsDetail.actualAmount }}</div>
              </div>
              <div class="frozen">
                <div>
                  <img src="../../../assets/college/Accounts/money.png" alt="" />
                  <span>冻结金额</span>
                </div>
                <div class="money">{{ accountsDetail.forzenAmount }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-row :gutter="22" class="elRow22" type="flex">
      <el-col :span="14">
        <el-card class="box-card-live">
          <div slot="header" class="header">
            <div class="title">直播服务</div>
            <div @click="buyRecord">
              <el-button class="btnPay" type="primary" @click="buyMeal">购买套餐</el-button>
              <el-button>购买记录</el-button>
            </div>
          </div>
          <div v-if="liveMealList.length" class="purchased">
            <span class="package">已购买套餐</span>
            <span class="time">超过并发按￥5.80 /人/小时</span>
          </div>
          <div v-if="liveMealList.length" class="liveMeal">
            <div
              v-for="item in liveMealList"
              :key="item.organizationLivePackageId"
              class="liveItem"
            >
              <div class="bgc"></div>
              <div class="livePackageName">
                <div class="txet">{{ item.livePackageName }}</div>
              </div>
              <div class="description">
                <div class="text">{{ item.description }}</div>
                <div class="validTime">有效期：{{ item.validTime }}</div>
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <div class="emptyBgc"><img src="../../../assets/teacher/empty.png" alt="" /></div>
            <div class="text">
              暂无购买套餐，计费方式为
              <span class="price">￥5.80</span>
              <span class="time">/人/小时</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card class="box-card-order">
          <div slot="header" class="clearfix header">
            <span class="title">我的订单</span>
          </div>
          <div class="orderContent">
            <div class="order_type" @click="waitOrder">
              <div class="order_type_icon"><i class="el-icon-s-finance"></i></div>
              <div class="order_type_text">待支付订单</div>
            </div>
            <div class="order_type distance" @click="allOrder">
              <div class="order_type_icon"><i class="el-icon-s-order"></i></div>
              <div class="order_type_text">全部订单</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <Increment />
    <BuyMealRecord ref="BuyMealRecord"></BuyMealRecord>
    <Recharge ref="Recharge"></Recharge>
    <seat-log ref="SeatLog"></seat-log>
    <give-success ref="GiveSuccess"></give-success>
  </div>
</template>

<script>
import Increment from '../../dashboard/components/Increment.vue'
import to from 'await-to'
import Recharge from './commonents/Recharge'
import { getAccountsMoney, getAccountsSeat, getGiveDetail, productGive } from '@/api/college'
import { mealPayRecord } from '@/api/live'
import SeatLog from './commonents/SeatLog.vue'
import BuyMealRecord from './commonents/BuyMealRecord.vue'
import GiveSuccess from './commonents/GiveSuccess.vue'
export default {
  components: {
    Recharge,
    SeatLog,
    BuyMealRecord,
    Increment,
    GiveSuccess,
  },
  data() {
    return {
      percentage: '',
      accountsDetail: {},
      seatDetail: {},
      giveDetail: {},
      liveMealList: [],
    }
  },
  created() {
    this.getAccountsMoneyData()
    // this.getAccountsSeatData()
    this.getGiveDetail()
    this.mealPayRecordData()

    if (this.$route.params.giveCourse == 1) {
      this.getFreeCourse()
    }
  },
  methods: {
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.accountsDetail = res.data
    },
    async getAccountsSeatData() {
      const [res, err] = await to(getGiveDetail())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.giveDetail = res.data
    },
    async getGiveDetail() {
      const [res, err] = await to(getAccountsSeat())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.seatDetail = res.data
      if (this.seatDetail.chargePackageCode != 'V_FREE') {
        this.getAccountsSeatData()
      }
    },
    async mealPayRecordData() {
      const [res, err] = await to(mealPayRecord({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.liveMealList = res.data
    },
    buyRecord() {
      this.$refs.BuyMealRecord.show()
    },
    recharge() {
      this.$refs.Recharge.show()
    },
    goDetailed() {
      this.$router.push({ path: '/finance/incomeList' })
    },
    addSeat() {
      this.$router.push('addSeat')
    },
    seatLog() {
      this.$refs.SeatLog.show()
    },
    toFreeCourseList() {
      this.$router.push('getFreeCourse')
    },
    async getFreeCourse() {
      const loading = this.$loading({
        lock: true,
        text: '领取课程中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const [res, err] = await to(productGive({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$refs.GiveSuccess.show(res.data)
      this.getGiveDetail()
      loading.close()
    },
    withdrawal() {
      this.$router.push('withdrawal')
    },
    handleUpgrade() {
      this.$router.push('upgradeVersion')
    },
    version() {
      this.$router.push('versionIntroduction')
    },
    waitOrder() {
      this.$router.push({ path: '/finance/orderList', query: { waitOrderPay: 'WAIT' } })
    },
    allOrder() {
      this.$router.push({ path: '/finance/orderList' })
    },
    buyMeal() {
      this.$router.push({ path: '/live/liveMeal' })
    },
  },
}
</script>

<style scoped lang="scss">
.accountsManage {
  ::v-deep .card {
    &.Increment {
      width: 100% !important;
    }
  }
}

.box-card {
  .cardTitle {
    font-size: 18px;
    color: #333;
  }
  .cardContent {
    border: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    .cardContent_l {
      width: 50%;
      min-width: 650px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1600px) {
        .seat {
          margin: 10px 0 !important;
          .addSeat {
            margin: 5px 0;
          }
        }
      }
      .cardContent_l_l {
        width: 209px;
        height: 196px;
        background-color: #fff;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .cardContent_l_info {
          position: absolute;
          left: 30px;
          top: 44px;
          .cardContent_l_nowEdition {
            font-size: 14px;
            color: #fee0de;
          }
          .cardContent_l_time {
            font-size: 14px;
            color: #fff;
            span {
              color: #f6bfb0;
              margin-left: 5px;
            }
          }
          .cardContent_l_free {
            font-size: 30px;
            color: #fff;
            font-weight: 500;
            margin: 13px 0 17px 0;
          }
          .cardContent_l_upgrade {
            font-size: 16px;
            color: #97540f;
            font-weight: 500;
            line-height: 40px;
            background-color: #f1c687;
            border-radius: 20px;
            width: 130px;
            text-align: center;
            cursor: pointer;
          }
          .enterprise_edition {
            color: #96723a;
          }
          .enterprise_nowEdition {
            color: #8e6a32;
          }
        }
      }
      .cardContent_l_r {
        margin-left: 18px;
        width: calc(100% - 209px - 18px);
        border-right: 1px dashed #e2e2e2;
        margin-right: 30px;
        height: 170px;
        .seat,
        .give {
          display: flex;
          align-items: center;
        }
        .seat {
          margin: 20px 0;
        }
        .info {
          height: 68px;
          width: 280px;
        }
      }
      .cardContent_l_r_people {
        font-size: 18px;
        color: #333;
        margin-bottom: 15px;
      }
      .cardContent_l_r_progress {
        width: 300px;
        ::v-deep .el-progress__text {
          display: none;
        }
      }
      .cardContent_l_r_detail {
        font-size: 14px;
        color: #ff7b33;
        text-decoration: underline;
        cursor: pointer;
      }
      .addSeat {
        display: inline-block;
        font-size: 14px;
        color: #ff7b33;
        width: 98px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #ff7b33;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    .cardContent_r {
      width: 50%;
      padding: 0 20px;
      box-sizing: border-box;
      height: 170px;
      background-color: #fff5f0;
      margin-right: 18px;
      .top {
        display: flex;
        align-items: center;
        padding: 13px 0;
        box-sizing: border-box;
        border-bottom: 1px dashed #e2e2e2;
        .cardContent_r_l {
          padding-left: 43px;
          box-sizing: border-box;
          .cardContent_r_l_balance {
            font-size: 16px;
            color: #909399;
          }
          .cardContent_r_l_money {
            font-size: 24px;
            color: #ff7b33;
          }
          .cardContent_r_l_detailed {
            font-size: 14px;
            color: #ff7b33;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .cardContent_r_r {
          margin-left: 80px;
        }
      }
      .bottom {
        display: flex;
        padding-left: 43px;
        height: 60px;
        align-items: center;
        font-size: 14px;
        .frozen {
          margin-left: 74px;
        }
        .money {
          font-size: 18px;
          color: #333;
          font-weight: 500;
          margin-left: 20px;
        }
        img {
          width: 18px;
          height: 15px;
          margin-right: 6px;
        }
      }
    }
  }
}

.elRow22 {
  margin-top: 20px;
  ::v-deep .el-card__header {
    padding: 0;
    padding-left: 24px;
    height: 54px;
  }
}
.box-card-order {
  height: 100%;
  .header {
    line-height: 54px;
    .title {
      font-size: 18px;
      color: #333;
    }
  }
  .orderContent {
    margin-top: 7px;
    margin-left: 44px;
    padding-bottom: 106px;
    display: flex;
    .distance {
      margin-left: 106px;
    }
    .order_type {
      cursor: pointer;
      .order_type_icon {
        width: 72px;
        line-height: 72px;
        text-align: center;
        background-color: #f5f5f5;
        border-radius: 50%;
        i {
          font-size: 27px;
          color: #ff7b33;
        }
      }
      .order_type_text {
        font-size: 16px;
        color: #333;
        margin-top: 11px;
      }
    }
  }
}

.box-card-live {
  height: 100%;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding-right: 31px;
    ::v-deep .el-button {
      padding: 8px 23px;
      height: 32px;
    }
    .btnPay {
      margin-right: 11px;
    }
  }
  .purchased {
    display: flex;
    align-items: center;
    color: #333333;
    .package {
      font-size: 18px;
    }
    .time {
      font-size: 14px;
      color: #909399;
      margin-left: 9px;
    }
  }
  .liveMeal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 18px;
    .liveItem {
      width: 400px;
      height: 95px;
      display: flex;
      border-radius: 4px;
      border: 1px solid #f1d098;
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-left: 50px;
      }
      .bgc {
        width: 8px;
        height: 100%;
        background: #ffe8c0;
        border-radius: 4px 0px 0px 4px;
      }
      .livePackageName {
        width: 62px;
        background: #fff8ec;
        color: #b57d1d;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        .txet {
          width: 16px;
        }
      }
      .description {
        padding-left: 29px;
        padding-top: 23px;
        .text {
          color: #333333;
          font-size: 16px;
        }
        .validTime {
          font-size: 14px;
          color: #909399;
          margin-top: 17px;
        }
      }
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    .emptyBgc {
      width: 112px;
      height: 112px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      font-size: 14px;
      color: #909399;
      margin-top: 19px;
      .price {
        color: #ff7b33;
        font-size: 22px;
      }
      .time {
        color: #333333;
      }
    }
  }
}

.accounts {
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .radio_wrap {
    display: flex;
    height: 40px;
    line-height: 40px;
    .radio_img {
      height: 20px;
      width: 20px;
      .weixin {
        width: 25px;
        height: 25px;
      }
    }
    i {
      font-size: 24px;
      color: #ff7b33;
    }
  }
}
.spanActive {
  color: #fff !important;
}
</style>
