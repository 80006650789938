<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <table-list
        class="tableList"
        :loading="loading"
        :data="list"
        :btns="btns(this)"
        :columns="columns(this)"
        :pager="pager"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-dialog>
  </div>
</template>

<script>
// 顶部按钮
const btns = _this => [
  {
    label: '购买套餐',
    type: 'primary',
    method: _this.handleBuyMeal,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderNo',
    label: '订单号',
  },
  {
    prop: 'livePackageName',
    label: '套餐',
  },
  {
    prop: 'settleTime',
    label: '购买时间',
  },
  {
    prop: 'packageStatus',
    label: '状态',
    formatter: row => {
      if (row.packageStatus == 0) {
        return '未生效'
      } else if (row.packageStatus == 1) {
        return '即将过期'
      } else if (row.packageStatus == 2) {
        return '生效中'
      } else {
        return '已过期'
      }
    },
  },
]
import { allMealBuyRecord } from '@/api/live'
import TableList from '@/components/TableList'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      dialogTitle: '直播套餐购买记录',
      loading: false,
      dialogVisible: false,
      list: [],
      btns,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  created() {},
  methods: {
    show() {
      this.dialogVisible = true
      this.allMealBuyRecord()
    },
    async allMealBuyRecord() {
      this.loading = true
      const [res, err] = await to(allMealBuyRecord(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    handleBuyMeal() {
      this.$router.push({ path: '/live/liveMeal' })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.allMealBuyRecord()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.allMealBuyRecord()
    },
  },
}
</script>

<style scoped></style>
