<template>
  <div class="card Increment">
    <el-card class="box-card">
      <div class="title">增值服务</div>
      <div class="list">
        <div class="listItem">
          <div class="service">直播服务</div>
          <div class="introduce">支持万人的稳定高质量课程直播功能</div>
          <div class="open" @click="toLive">开通服务</div>
          <div class="bgc"><img src="../../../assets/college/Accounts/sy.png" alt="" /></div>
        </div>
        <!-- <div class="listItem">
          <div class="service">同等学力申硕</div>
          <div class="introduce">帮助企业培养高素质的人才服务项目</div>
          <div class="open" @click="detail">查看详情</div>
          <div class="bgc"><img src="../../../assets/college/Accounts/bs.png" alt="" /></div>
        </div> -->
        <div class="listItem" @click="toCustom">
          <div class="service">定制线上课程</div>
          <div class="introduce">提供课程研究及课程制作等一体化服务</div>
          <div class="open">查看详情</div>
          <div class="bgc"><img src="../../../assets/college/Accounts/ds.png" alt="" /></div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {
    toLive() {
      this.$router.push('/college/liveIntroduction')
    },
    detail() {
      this.$router.push('/college/learningAbility')
    },
    toCustom() {
      this.$router.push('/college/customizedCourse')
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  &.Increment {
    width: calc(100% - 383px);
    margin-top: 20px;
    padding-bottom: 43px;
    .title {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .list {
      display: flex;
      .listItem {
        width: 384px;
        height: 185px;
        color: #fff;
        padding: 28px 0 0 30px;
        margin-left: 26px;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        &:nth-child(1) {
          background-color: #6baef8;
          margin-left: 0;
        }
        &:nth-child(2) {
          background: #ffc35b;
        }
        &:nth-child(3) {
          background: #85d484;
        }
        .service {
          font-size: 18px;
          line-height: 22px;
        }
        .introduce {
          font-size: 14px;
          margin-top: 14px;
          margin-bottom: 29px;
          line-height: 22px;
        }
        &:nth-child(1) .open {
          background: #4794e8;
        }
        &:nth-child(2) .open {
          background: #f1a728;
        }
        &:nth-child(3) .open {
          background: #54c152;
        }
        .open {
          width: 100px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }
        &:nth-child(1) .bgc {
          width: 130px;
          height: 84px;
        }
        &:nth-child(2) .bgc {
          width: 132px;
          height: 100px;
        }
        &:nth-child(3) .bgc {
          width: 119px;
          height: 110px;
        }
        .bgc {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
