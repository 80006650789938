<template>
  <el-dialog custom-class="give-success" :visible.sync="dialogVisible" width="560px">
    <img src="@/assets/college/Accounts/success_icon.png" alt="" />
    <div class="title">领取成功</div>
    <p>您成功领取{{ num }}门精品课程</p>
    <p>课程可以在“书院课程库-买断课程”列表查看和使用</p>
    <el-button type="primary" @click="dialogVisible = false">确定</el-button>
  </el-dialog>
</template>
<script>
export default {
  name: 'GiveSuccess',
  data() {
    return {
      dialogVisible: false,
      num: 0,
    }
  },
  methods: {
    show(num) {
      this.num = num
      this.dialogVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .give-success {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #999999;
    }
    .el-button {
      margin-top: 26px;
    }
  }
}
</style>
